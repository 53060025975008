<template>
  <div>
    <div class="permissionsWrapper">
      <div class="checkboxContainer inlineDiv">
        <v-card outlined>
          <v-card-title>
            <div class="headerLineInWindow">
              {{ language.componentLabel.titleTests }}
            </div></v-card-title
          >
          <v-card-text class="mt-n2">
            <v-row no-gutters style="max-height: 40px">
              <v-col class="height25">
                <permissionCheckBox
                  label="View"
                  :storedPermissions="storedPermissions"
                  value="1K"
                  :disabled="disabled"
                  @input="updatePermission"
                ></permissionCheckBox>
              </v-col>
              <v-col class="height25">
                <permissionCheckBox
                  label="Start"
                  :storedPermissions="storedPermissions"
                  value="1D"
                  :disabled="disabled"
                  @input="updatePermission"
                ></permissionCheckBox>
              </v-col>
              <v-col class="height25">
                <permissionCheckBox
                  label="Modify Own"
                  :storedPermissions="storedPermissions"
                  value="1O"
                  :disabled="disabled"
                  @input="updatePermission"
                ></permissionCheckBox>
              </v-col>
            </v-row>
            <v-row no-gutters style="max-height: 40px">
              <v-col></v-col>
              <v-col class="height25">
                <permissionCheckBox
                  label="Stop All"
                  :storedPermissions="storedPermissions"
                  value="1F"
                  :disabled="disabled"
                  @input="updatePermission"
                ></permissionCheckBox>
              </v-col>
              <v-col class="height25">
                <permissionCheckBox
                  label="Modify Any"
                  :storedPermissions="storedPermissions"
                  value="1N"
                  :disabled="disabled"
                  @input="updatePermission"
                ></permissionCheckBox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-card outlined class="mt-2">
        <v-card-title>
          <div class="headerLineInWindow">
            {{ language.componentLabel.titleConfiguration }}
          </div></v-card-title
        >
        <v-card-text>
          <v-row no-gutters>
            <v-col style="min-width: 200px"></v-col>
            <v-col
              v-for="(permissionType, key) in fieldAttributes.permissions
                .groupPermissionsTable"
              :key="key"
            >
              <span v-if="permissionType.name != 'Start'" class="text-subtitle-1">{{
                permissionType.name
              }}</span>
            </v-col>
          </v-row>

          <div
            v-for="(permission, index) in fieldAttributes.permissions
              .permissionsGroups"
            :key="index"
          >
            <v-row
              no-gutters
              v-if="permission.name != 'msisdnProvision'"
              style="max-height: 40px"
            >
              <v-col style="min-width: 200px" class="mt-5 text-subtitle-1">{{
                languagePack("menu_" + permission.name)
              }}</v-col>
              <v-col
                v-for="(permissionType, i) in fieldAttributes.permissions
                  .groupPermissionsTable"
                :key="i"
              >
                <div
                  v-if="
                    permission.maxPermissions.includes(
                      permissionType.permission
                    )
                  "
                >
                  <checkbox
                    :fieldAttrInput="{}"
                    :fieldAttributes="{}"
                    @input="
                      updatePermission(
                        permission.index + permissionType.permission,
                        ...arguments
                      )
                    "
                    :value="getChecked(permission, permissionType)"
                  ></checkbox>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <input type="hidden" :value="value" name="c_permissions" />
  </div>
</template>
<script>
import permissionCheckBox from "@/components/legacy/permissionCheckBox.vue";
import checkbox from "@/commonComponents/checkbox.vue";
export default {
  components: { permissionCheckBox, checkbox },
  props: ["result", "fieldAttributes", "value"],
  computed: {
    disabled() {
      let type = this.result.n_type;
      let disabled = false;
      if (type == 2) {
        disabled = true;
      }
      return disabled;
    },
    storedPermissions() {
      let storedPermissions = [];
      if (typeof this.value != "undefined") {
        storedPermissions = this.value.split(" ");
      }
      return storedPermissions;
    },
  },
  methods: {
    updatePermission(index, value) {
      let storedPermissions = [...this.storedPermissions];
      if (value == 1) {
        storedPermissions.push(index);
      } else {
        storedPermissions = storedPermissions.filter((item) => item !== index);
      }
      this.$emit("input", storedPermissions.join(" "));
    },
    getChecked(permission, permissionType) {
      let checked = 0;
      let checkedString = permission.index + permissionType.permission;
      if (this.storedPermissions.includes(checkedString)) {
        checked = 1;
      }
      return checked;
    },
  },
};
</script>
        