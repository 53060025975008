var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"permissionsWrapper"},[_c('div',{staticClass:"checkboxContainer inlineDiv"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"headerLineInWindow"},[_vm._v(" "+_vm._s(_vm.language.componentLabel.titleTests)+" ")])]),_c('v-card-text',{staticClass:"mt-n2"},[_c('v-row',{staticStyle:{"max-height":"40px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"height25"},[_c('permissionCheckBox',{attrs:{"label":"View","storedPermissions":_vm.storedPermissions,"value":"1K","disabled":_vm.disabled},on:{"input":_vm.updatePermission}})],1),_c('v-col',{staticClass:"height25"},[_c('permissionCheckBox',{attrs:{"label":"Start","storedPermissions":_vm.storedPermissions,"value":"1D","disabled":_vm.disabled},on:{"input":_vm.updatePermission}})],1),_c('v-col',{staticClass:"height25"},[_c('permissionCheckBox',{attrs:{"label":"Modify Own","storedPermissions":_vm.storedPermissions,"value":"1O","disabled":_vm.disabled},on:{"input":_vm.updatePermission}})],1)],1),_c('v-row',{staticStyle:{"max-height":"40px"},attrs:{"no-gutters":""}},[_c('v-col'),_c('v-col',{staticClass:"height25"},[_c('permissionCheckBox',{attrs:{"label":"Stop All","storedPermissions":_vm.storedPermissions,"value":"1F","disabled":_vm.disabled},on:{"input":_vm.updatePermission}})],1),_c('v-col',{staticClass:"height25"},[_c('permissionCheckBox',{attrs:{"label":"Modify Any","storedPermissions":_vm.storedPermissions,"value":"1N","disabled":_vm.disabled},on:{"input":_vm.updatePermission}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"headerLineInWindow"},[_vm._v(" "+_vm._s(_vm.language.componentLabel.titleConfiguration)+" ")])]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"min-width":"200px"}}),_vm._l((_vm.fieldAttributes.permissions
              .groupPermissionsTable),function(permissionType,key){return _c('v-col',{key:key},[(permissionType.name != 'Start')?_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(permissionType.name))]):_vm._e()])})],2),_vm._l((_vm.fieldAttributes.permissions
            .permissionsGroups),function(permission,index){return _c('div',{key:index},[(permission.name != 'msisdnProvision')?_c('v-row',{staticStyle:{"max-height":"40px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-5 text-subtitle-1",staticStyle:{"min-width":"200px"}},[_vm._v(_vm._s(_vm.languagePack("menu_" + permission.name)))]),_vm._l((_vm.fieldAttributes.permissions
                .groupPermissionsTable),function(permissionType,i){return _c('v-col',{key:i},[(
                  permission.maxPermissions.includes(
                    permissionType.permission
                  )
                )?_c('div',[_c('checkbox',{attrs:{"fieldAttrInput":{},"fieldAttributes":{},"value":_vm.getChecked(permission, permissionType)},on:{"input":function($event){return _vm.updatePermission(
                      permission.index + permissionType.permission,
                      ...arguments
                    )}}})],1):_vm._e()])})],2):_vm._e()],1)})],2)],1)],1),_c('input',{attrs:{"type":"hidden","name":"c_permissions"},domProps:{"value":_vm.value}})])
}
var staticRenderFns = []

export { render, staticRenderFns }