<template>
  <div>
    <!-- {$checked=""}
    {if in_array("1K",$storedPermissions)}
    {$checked="checked=checked"}
    {/if}-->
    <checkbox :fieldAttrInput="{id:'permissionGroup_'+value}" :fieldAttributes="{name:label}" v-model="sentValue"></checkbox>
    <!-- <input
      {$disabled}
      class="permissionView"
      type="checkbox"
      name="{$field}[]"
      value="1K"
      {$checked}
    />View-->
  </div>
</template>
<script>
import checkbox from "@/commonComponents/checkbox.vue";
export default {
  components: { checkbox },
  props: ["value", "storedPermissions", "label"],
  computed: {
    sentValue: {
      get() {
        if (this.storedPermissions.includes(this.value)) {
          return 1;
        } else {
          return 0;
        }
      },
      set(newVal) {
        this.$emit("input", this.value, newVal);
      },
    },
  },
};
</script>

